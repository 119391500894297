import React from 'react'
import * as styles from "./_AI_Path.module.scss"
import { AIIcon, GenemodGrayLogo } from "@assets/icons";

export function Circle() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle className={styles.circlePath} cx="5" cy="5" r="4" stroke="#5D7AF9"/>
    </svg>
  )
}

export function DottedLine({ length = 16 }) {
  return (
    <svg width="2" height={length} viewBox={`0 0 2 ${length}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={styles.dottedProgress} d={`M1 0V${length}`} stroke="#5D7AF9" stroke-width="1.5" stroke-dasharray="4 8"/>
    </svg>
  );
}

export function Arrow() {
  return (
    <svg className={styles.glowingArrow} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 6L8 10L12 6" stroke="#5D7AF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export function AIBadge() {
  return (
    <div className={styles.aibagde}>
      <AIIcon />
      <span >
        AI
      </span>
    </div>
  )
}

export function CurvePath() {
  return (
    <>
      <div className={styles.isDesktop}>
        <svg width="406" height="86" viewBox="0 0 406 86" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className={styles.dottedProgress} d="M400.032 13C400.032 13 400.032 14.5 400.032 29C400.032 32.5 394.773 38 390.195 40.5C385.616 43 368.134 44.5 362.722 44.5C321.443 44.5 157.961 44 36.7144 44C31.2199 44 24.8096 42.5 12.9048 47.5C1.00004 52.5 1.00004 54.5 1.00004 59C1.00004 72 1.00004 86 1.00004 86" stroke="#5D7AF9" stroke-width="1.5" stroke-dasharray="4 8"/>
          <path className={styles.dottedProgress} d="M399.657 0L405.314 5.65685L399.657 11.3137L394 5.65685L399.657 0Z" fill="#5D7AF9"/>
        </svg>
      </div>
      <div className={styles.isTablet}>
        <svg width="220" height="73" viewBox="0 0 220 73" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className={styles.dottedProgress} d="M218.871 0C218.871 0 218.871 1.5 218.871 16C218.871 19.5 216 25 213.5 27.5C211 30 201.455 31.5 198.5 31.5C175.962 31.5 86.7005 31 20.5 31C17.5 31 14 29.5 7.5 34.5C1 39.5 1 41.5 1 46C1 59 1 73 1 73" stroke="#5D7AF9" stroke-width="1.5" stroke-dasharray="4 8"/>
        </svg>
      </div>
      <div className={styles.isMobile}>
        <svg width="80" height="85" viewBox="0 0 80 85" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className={styles.dottedProgress} d="M73.6569 0L79.3137 5.65685L73.6569 11.3137L68 5.65685L73.6569 0Z" fill="#5D7AF9"/>
          <path className={styles.dottedProgress} d="M73.5001 12C73.5001 12 73.5001 13.5 73.5001 28C74 31 70 37 68.5001 38C67.6544 38.5638 61.2736 42.5 58.0001 42.5C50.5001 42.5 39.0294 42.5 17.0001 42.5C12.0001 42.5 7.19788 44.8777 5.50001 47C3.60913 49.3636 1.00001 53.5 1.00001 58C1.00001 71 1.00001 85 1.00001 85" stroke="#5D7AF9" stroke-width="1.5" stroke-dasharray="4 8"/>
        </svg>
      </div>
    </>
  )
}
